import React,{useState} from "react"
import { useBreakpoint } from "hooks/use-breakpoint"

import SectionHeader from "components/sectionHeader"
import Card from "components/card"

import styles from "styles/sections/tiers-section.module.scss"
import styleVars from "styles/variables.scss"
import BackgroundTiers from "images/background-tiers.svg"
import BackgroundTiersMobile from "images/background-tiers-mobile.svg"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const TiersSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
}) => {
  let fullWidth = false
  const [isLaptopSmall, loaded] = useBreakpoint(styleVars.breakpointLaptopSmall, -1)
  const isWavy = backgroundType === "wavy"
  const moreThanThree = gridItems.length > 3
  if(gridItems.length === 4 && gridItems[0].checklist){
    fullWidth = true
  }
  
  const [isExpand, setIsExpand] = useState(false)
  const clickButton = (e)=>{
    setIsExpand(e)
  }
  return (
    <section
      className={cx("container", {
        white: backgroundType === "White",
        lightMint: backgroundType === "Light Gray",
        mint: backgroundType === "Dark Gray",
        wavy: isWavy
      })}
      id={sectionId}
    >
      {isWavy && loaded &&
        (isLaptopSmall ? <BackgroundTiersMobile /> : <BackgroundTiers />)}
      <SectionHeader
        centered
        white={isWavy}
        maxWidthTitle={isLaptopSmall ? '600px': '755px'}
        {...sectionHeading}
      />
      <div className={cx("cardContainer", { moreThanThree, fullWidth })}>
        {gridItems &&
          gridItems.map((card, i) => (
            <Card
              shadow={backgroundType !== "wavy"}
              vertical
              moreThanThree={moreThanThree}
              {...card}
              key={i}
              fullWidth={fullWidth}
              isExpand={isExpand}
              clickHandle={clickButton}
            />
          ))}
        <Card vertical dummy />
        <Card vertical dummy />
      </div>
    </section>
  )
}

export default TiersSection
