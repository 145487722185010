import React from "react"
import { graphql } from "gatsby"
import { GRID_TYPES } from "constants.js"
import CardSection from "./cardSection"
import SimpleCardsSection from "./simpleCardsSection"
import TextGridSection from "./textGridSection"
import OneColText from "./oneColTextSection"
import ThreeColsText from "./threeColsTextSection"
import ThreeColsImageAndText from "./threeColsImageAndTextSection"
import LeadershipSection from "./leadershipSection"
import TiersSection from "./tiersSection"
import ArticleGridSection from "./articleGridSection"
import TeamSection from "./teamSection"
import ExternalArticleSection from "./externalArticleSection"
import ImageGridSection from "./imageGridSection"
import ThreeColsTextIconSection from "./threeColsTextIconSection"

const GridSection = (props) => {
  switch (props.type) {
    case GRID_TYPES.card:
      return <CardSection {...props} />
    case GRID_TYPES.simpleCard:
      return <SimpleCardsSection {...props} />
    case GRID_TYPES.text:
      return <TextGridSection {...props} />
    case GRID_TYPES.oneColText:
      return <OneColText {...props} />
    case GRID_TYPES.threeColsText:
      return <ThreeColsText {...props} />
    case GRID_TYPES.threeColsImageAndText:
      return <ThreeColsImageAndText {...props} />
    case GRID_TYPES.leadership:
      return <LeadershipSection {...props} />
    case GRID_TYPES.tiers:
      return <TiersSection {...props} />
    case GRID_TYPES.article:
      return <ArticleGridSection {...props} />
    case GRID_TYPES.team:
      return <TeamSection {...props} />
    case GRID_TYPES.externalArticle:
      return <ExternalArticleSection {...props} />
    case GRID_TYPES.image:
      return <ImageGridSection {...props} />
    case GRID_TYPES.threeColsTextIcon:
      return <ThreeColsTextIconSection {...props} />
    default:
      return null
  }
}

export default GridSection

export const gridSection = graphql`
  fragment GridSection on ContentfulGridSection {
    __typename
    id
    type
    sectionId
    sectionHeading {
      ...SectionHeading
    }
    backgroundType
    gridItems {
      ... on ContentfulCard {
        title
        subtitle
        text
        content {
          childMarkdownRemark {
            html
          }
        }
        color
        icon {
          title
          file {
            url
          }
          fluid(quality: 100, maxWidth: 360) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        buttonLink {
          ...Link
        }
        checklist {
          ... on ContentfulText {
            ...Text
          }
        }
        buttonText
      }
      ... on ContentfulTextBlock {
        title
        text
        titleColor
        icon {
          title
          file {
            url
          }
        }
      }
      ... on ContentfulLongTextBlock {
        longTextBlockButtonLink: buttonLink
        longTextBlockButtonText: buttonText
        title
        titleColor
        longText: text {
          childMarkdownRemark {
            html
          }
        }
        icon {
          title
          file {
            url
          }
          fluid(quality: 100, maxWidth: 360) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulArticle {
        ...ArticleThumbnail
      }
      ... on ContentfulExternalArticle {
        title
        source
        description
        link
        image {
          title
          fluid(quality: 100, maxWidth: 360) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulImageBlock {
        name
        image {
          title
          file {
            url 
          }
          fluid(quality: 100, maxWidth: 360) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
      }
      ... on ContentfulLeadership {
        childContentfulLeadershipDescriptionRichTextNode {
          json
        }
        childContentfulLeadershipNameleadershipRichTextNode {
          json
        }
        childContentfulLeadershipPositionRichTextNode {
          json
        }
        image {
          title
          file {
            url
          }
        }
      }
    }
    link {
      ...Link
    }
    loadMoreButton
  }
`
