// Strings used in Contentful to define types
export const GRID_TYPES = {
  card: "card",
  simpleCard: "simple card",
  tiers: "tiers",
  text: "text",
  article: "article",
  team: "team",
  externalArticle: "external article",
  threeColsText: "3 cols text",
  threeColsImageAndText: '3 cols text and image',
  oneColText: "1 col text",
  leadership: "leadership",
  image: "image",
  threeColsTextIcon: "3 cols text icon"
}

export const HERO_TYPES = {
  video: "video",
  textAndImage: "text and image",
  image: "image",
  map: "map",
  textCenter: 'text center',
  mix: 'mix'
}

export const CAROUSEL_TYPES = {
  quote: "quote",
  image: "image",
  logo: "logo",
  banner: "banner"
}

export const TITLE_COLOR = {
  'White': 'white',
  'Teal': 'teal',
  'Navy': 'navy',
  'Gray': 'gray',
  'Manatee': 'Manatee'
}
export const BACKGROUND_TYPE = {
  'White': 'bg-white',
  'Navy': 'bg-navy',
  'Light Gray': 'bg-light-gray',
  'Dark Gray': 'bg-dark-gray',
  'Teal': 'bg-teal',
  'Teal Wave': 'bg-teal-wave'
}