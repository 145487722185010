import React, { useState, useRef } from "react"
import Reveal from "react-reveal/Reveal"

import ContentfulLink from "components/contentfulLink"
import TwoColumnList from "components/twoColumnList"
import "../styles/animations.scss"
import styles from "../styles/components/card.module.scss"
import RightCarrot from "images/icons/right-carrot.svg"
import icons from "helpers/icons"
import classNames from "classnames/bind"
import { useBreakpoint } from "hooks/use-breakpoint"
import styleVars from "styles/variables.scss"

const cx = classNames.bind(styles)
const MAP_COLOR_TYPE = {
  'Teal': 'teal',
  'Navy': 'navy',
  'Light Gray': 'mint',
  'Dark Gray': 'grey'
}
const Card = (props) => {
  const [isTablet, isLoad] = useBreakpoint(styleVars.breakpointDesktop)

  const expandArrow = (e) => {
    e.preventDefault()
    props.clickHandle(!props.isExpand)
    if (!props.isExpand) {
      let height = 0
      const lists = document.querySelectorAll("div[name=newslist]")
      for (let i = 0; i < lists.length; i++) {
        if (lists[i].getBoundingClientRect().height > height) {
          height = lists[i].getBoundingClientRect().height
        }
        if (isTablet && i == 2) {
          break
        }
      }
      for (let i = 0; i < lists.length; i++) {
        lists[i].style.height = height + "px"
        if (isTablet && i == 2) {
          break
        }
      }
    }
  }
  const ref = useRef(null)
  const { withoutAnimation } = props

  return (
    <div
      className={cx("card", MAP_COLOR_TYPE[props.color], {
        dummy: props.dummy,
        horizontal: props.horizontal,
        vertical: props.vertical,
        shadow: props.shadow,
        moreThanThree: props.moreThanThree, // enables 2 column layout
        fullWidth: props.fullWidth
      })}
    >
      <div className={`${styles.flex} ${props.isExpand ? styles.full : ''}`}>
        <div className={styles.flex}>
          <div className={styles.relative} name="newslist">
            {props.icon?.file.url && (
              <>
                {withoutAnimation && (
                  <div className={styles.icon}>
                    <img src={props.icon.file.url} alt={props.title} />
                  </div>
                )}
                {!withoutAnimation && (
                  <Reveal effect="fadeIn">
                    <div className={styles.icon}>
                      <img src={props.icon.file.url} alt={props.title} />
                    </div>
                  </Reveal>
                )}
              </>
            )}
              <div className={styles.top}>
                {withoutAnimation && (
                  <>
                    <h6 className={cx("title", MAP_COLOR_TYPE[props.color])}>{props.title}</h6>
                    {props.text && <p className={styles.text}>{props.text}</p>}
                    {props.content && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: props.content.childMarkdownRemark.html,
                        }}
                        className={styles.description}
                      />
                    )}
                  </>
                )}
                {!withoutAnimation && (
                  <>
                    <Reveal effect="fadeIn">
                      <h6 className={cx("title", MAP_COLOR_TYPE[props.color])}>{props.title}</h6>
                    </Reveal>
                    <Reveal effect="fadeUp" duration={200}>
                      <p className={styles.text}>{props.text}</p>
                    </Reveal>
                    {props.content && (<Reveal effect="fadeUp" duration={200}>
                      <p className={styles.text}>{props.text}</p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: props.content.childMarkdownRemark.html,
                        }}
                        className={styles.description}
                      />
                    </Reveal>)}
                  </>
                )}
              </div>
          </div>
          {props.buttonLink && (
            <div className={styles.linkContainer}>
              <ContentfulLink {...props.buttonLink} className={styles.cta}>
                {props.buttonLink.linkText}
                {props.horizontal && <RightCarrot />}
              </ContentfulLink>
            </div>
          )}
        </div>
        {props.checklist && (
          <div >
            <span className={`${styles.dFlex} ${styles.alignCenter} ${styles.pointer} ${styles.iconButton}`} onClick={expandArrow} ref={ref}>
              {props.buttonText ? props.buttonText : "View more"}
              <span className={`${styles.arrowExpand} ${props.isExpand ? styles.expaned : ''}`} >
                {icons.arrow}
              </span>
            </span>
            <div  className={cx({ hidden: !props.isExpand })}>
              <TwoColumnList red oneColumn list={props.checklist} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Card