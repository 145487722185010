import React from 'react'
import { graphql } from 'gatsby'

import ContentfulLink from "components/contentfulLink"
import { useBreakpoint } from "hooks/use-breakpoint"
import Reveal from 'react-reveal/Reveal'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import LinkTextWithChev from "components/linkTextWithChev"
import ChevLeftWhite from "images/icons/chev-left-white.svg"
import ChevRightWhite from "images/icons/chev-right-white.svg"
import styles from "styles/sections/industries-section.module.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1501 },
    items: 3,
    partialVisibilityGutter: 40
  },
  desktop: {
    breakpoint: { max: 1500, min: 1200 },
    items: 2,
    partialVisibilityGutter: 150
  },
  tablet: {
    breakpoint: { max: 1199, min: 768 },
    items: 1,
    partialVisibilityGutter: 120
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    partialVisibilityGutter: 30
  },
}

const CustomButtonGroup = ({ next, previous }) => {
  return (
    <div className={cx('slideControls')}>
      <button aria-label="Previous button" className={cx('previousBtn')} onClick={() => previous()}>
        <ChevLeftWhite/>
      </button>
      <button aria-label="Next button" className={cx('nextBtn')} onClick={() => next()}>
        <ChevRightWhite />
      </button>
    </div>
  );
};
const IndustriesSection = ({
  header,
  industriesList = []
}) => {
  const [isTablet, loaded] = useBreakpoint(768)
  const autoPlaySpeed = 3000
  return (
    <section className={cx('sectionIndustries','wrapper')}>
      <Reveal effect="fadeInUp" duration={1000}>
        <>
          <h2>{header}</h2>
          <div className={styles.mainSlider}>
            <Carousel
              responsive={responsive}
              arrows={false}
              customButtonGroup={<CustomButtonGroup />}
              partialVisible={true}
              autoPlay
              infinite
              autoPlaySpeed={autoPlaySpeed}
              itemClass={cx('slideItem')}
            >
              {industriesList.map((industryItem, index) => {
                const ctaLink = {
                  linkText: industryItem.buttonText
                }
                if (industryItem.buttonLink.includes('://')) {
                  ctaLink.externalLink = industryItem.buttonLink
                } else {
                  ctaLink.internalLink = industryItem.buttonLink
                }
                return (
                  <div className={styles.boxShadow}>
                    <div
                      key={`slide-industry-${index}`}
                      className={cx('content')}
                    >
                      {!isTablet && loaded && (
                        <div className={cx('background', 'default', 'hideOnHover')} />
                      )}
                      <div className={cx('background', !isTablet && loaded && 'showOnHover')} style={{ backgroundImage: loaded && `url(${industryItem.image?.file?.url}), linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8))` }} />
                
                      <div className={cx('label')}>{industryItem.subheader}</div>
                      
                      <h3 className={cx('title')}>{industryItem.header}</h3>

                      <div className={!isTablet && cx('showOnHover')}>
                        <div
                          className={cx('description')}
                          dangerouslySetInnerHTML={{
                            __html: industryItem.content?.childMarkdownRemark?.html,
                          }}
                        />
                        <div className={cx('link')}>
                          <ContentfulLink {...ctaLink}>
                            <LinkTextWithChev text={ctaLink.linkText} />
                          </ContentfulLink>
                        </div>
                      </div>
                      {!isTablet && (
                        <div className={cx('image', 'hideOnHover')} style={{ backgroundImage: `url(${industryItem.image?.file?.url})` }} />
                      )}
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </div>
        </>
      </Reveal>
    </section>
  )
}

export default IndustriesSection

export const industriesSection = graphql`
  fragment IndustriesSection on ContentfulIndustriesSection {
    __typename
    header
    industriesList {
      buttonLink
      buttonText
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
      }
      subheader
      header
    }
  }
`
