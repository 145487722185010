import React from "react"
import "styles/animations.scss"
import { graphql } from 'gatsby'

import PaddedContent from "components/paddedContent"
import ContentfulLink from "components/contentfulLink"
import LinkTextWithChev from "components/linkTextWithChev"
import EncompassSectionBgSvg from "images/encompass-section-bg.svg"

import styles from "styles/sections/posts-section.module.scss"
import classNames from "classnames/bind"
import Reveal from 'react-reveal/Reveal';
const cx = classNames.bind(styles)

const PostItem = ({
  title,
  description,
  heroImage,
  slug,
  showImage
}) => {
  const ctaLink = {
    linkText: 'Read more',
    internalLink: `/blog/${slug}`
  }
  return (
    <div className={cx('postItem')}>
      {showImage && (
        <div className={cx('postItemImage')} style={{ backgroundImage: `url(${heroImage?.file?.url})` }}>
        </div>
      )}
      <div className={cx('content')}>
        <h3 className={cx('titleBlog')}>{title}</h3>
        <p>{description}</p>

        {slug && (
          <ContentfulLink {...ctaLink}>
            <LinkTextWithChev text={ctaLink.linkText} />
          </ContentfulLink>
        )}
      </div>
    </div>
  )
}

const PostsSection = ({
  header,
  buttonLink,
  buttonText,
  postsList = []
}) => {
  return (
    <section className={cx("container")}>
      <div className={cx('relative')}>
        <Reveal effect="fadeInUp" duration={1000}>
          <div className={cx('contentContainer')}>
            <PaddedContent>
              <div className={cx('sectionBg')}>
                <EncompassSectionBgSvg />
              </div>
              <div className={cx('wrapHeader')}>
                <h2>
                  <span className={cx('textLineLast')}>{header}</span>
                </h2>
              </div>
              <div className={cx('hiddenLaptop', 'headingLine')}></div>
            </PaddedContent>
          </div>
        </Reveal>
        <Reveal effect="fadeInUp" duration={1000}>
          <div className={cx('hiddenDesktop', 'headingLine')}></div>
          <div className={cx('contentContainer')}>
            <PaddedContent>
              {postsList && ( <div className={cx('wrapPost')}>
                {postsList.length > 0 && postsList[0] && (
                  <PostItem {...postsList[0]} showImage />
                )}
                <div className={cx('wrapSmallPost')}>
                  {postsList.length > 1 && postsList[1] && (
                    <PostItem {...postsList[1]} />
                  )}
                  {postsList.length > 2 && postsList[2] && (
                    <PostItem {...postsList[2]} />
                  )}
                </div>
              </div>)}
              <ContentfulLink internalLink={buttonLink} className={cx('cta')}>
                {buttonText}
              </ContentfulLink>
            </PaddedContent>
          </div>
        </Reveal>
      </div>
    </section>
  )
}

export default PostsSection


export const postsSection = graphql`
  fragment PostsSection on ContentfulPostsSection {
    header
    buttonLink
    buttonText
    postsList {
      title
      description
      slug
      heroImage {
        file {
          url
        }
      }
    }
  }
`
