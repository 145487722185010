import React from "react"
import { graphql } from "gatsby"
import { CAROUSEL_TYPES } from "constants.js"
import QuoteCarousel from "./quoteCarousel"
import ImageCarousel from "./imageCarousel"
import CarouselBackgroundImageItem from "./carouselBackgroundImageItem"
import BrandCarousel from "./brandCarousel"

const CarouselSection = (props) => {
  switch (props.type) {
    case CAROUSEL_TYPES.quote:
      return <QuoteCarousel {...props} />
    case CAROUSEL_TYPES.image:
      return <ImageCarousel {...props} />
    case CAROUSEL_TYPES.banner:
      return <CarouselBackgroundImageItem {...props} />
    case CAROUSEL_TYPES.logo:
      return <BrandCarousel {...props} />
    default:
      return null
  }
}

export default CarouselSection

export const carousel = graphql`
  fragment CarouselSection on ContentfulCarouselSection {
    __typename
    id
    sectionId
    sectionHeading {
      ...SectionHeading
    }
    type
    backgroundType
    delay
    carouselItems {
      ... on ContentfulQuoteCard {
        name
        title
        quote
        childContentfulQuoteCardQuoteTextNode {
          childMarkdownRemark {
            html
          }
        }
        color
        video {
          ...Video
        }
        videoThumbnail {
          title
          fluid(maxWidth: 372, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        cornerThumbnail {
          title
          fluid(maxWidth: 74, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        cornerThumbnailType
      }
      ... on ContentfulImageCarouselItem {
        stat
        longQuote: quote {
          quote
        }
        name
        position
        video {
          ...Video
        }
        backgroundImageDesktop {
          title
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        backgroundImageMobile {
          title
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      ... on ContentfulCarouselBackgroundImageItem {
        name
        backgroundImageDesktop {
          title
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        backgroundImageMobile {
          title
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
       }
      }
    }
    carouselLogos {
      title
      fluid(maxWidth: 130, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    link {
      linkText
      internalLink
    }
  }
`
