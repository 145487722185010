
import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import PaddedContent from "components/paddedContent"
import ContentfulLink from "components/contentfulLink"
import { useBreakpoint } from 'hooks/use-breakpoint'
import Reveal from 'react-reveal/Reveal'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import LinkTextWithChev from "components/linkTextWithChev"
import ChevLeft from "images/icons/chev-left.svg"
import ChevRight from "images/icons/chev-right.svg"
import DoubleQuotationMarksIcon from "images/icons/double-quotation-marks.svg"
import styles from "styles/sections/testimonial-section.module.scss"
import styleVars from "styles/variables.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const CustomButtonGroup = ({ total, next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  let __index = currentSlide - 2
  if (__index <= -1) {
    __index = total - 1
  }
  return (
    <div className={cx('slideControls')}>
      <button aria-label="Previous button" className={cx('previousBtn')} onClick={() => previous()}>
        <ChevLeft />
      </button>
      <div className={cx('flex', 'flexWrap', 'justifyCenter')}>
        {[...new Array(total)].map((e, index) => (
          <button aria-label={`Slide Dot ${index}`} className={cx('dot', __index === index ? 'active' : '')} key={`slide-testiminial-dot-${index}`} onClick={() => goToSlide(index + 2)} />
        ))}
      </div>
      <button aria-label="Next button" className={cx('nextBtn')} onClick={() => next()}>
        <ChevRight />
      </button>
    </div>
  );
};

const CustomLeftArrow = ({ onClick }) => {
  return (
    <button aria-label="Previous button" className={cx('customPreviousBtn')} onClick={() => onClick()}>
      <ChevLeft />
    </button>
  )
}
const CustomRightArrow = ({ onClick }) => {
  return (
    <button aria-label="Next button" className={cx('customNextBtn')} onClick={() => onClick()}>
      <ChevRight />
    </button>
  )
}

const TestimonialSlideItem = ({
  isTablet,
  author,
  quote,
  jobDescription,
  caseStudyExcerpt,
  caseStudyLink,
  caseStudyLogo,
  image
}) => {
  const ctaLink = {
    linkText: 'Read the case study',
  }
  if (caseStudyLink) {
    if (caseStudyLink.includes('://')) {
      ctaLink.externalLink = caseStudyLink
      ctaLink.openInNewTab = true
    } else {
      ctaLink.internalLink = caseStudyLink
    }
  }
  return (
    <div className={cx('slideItem')}>
      <div className={cx('imageAndText')}>
        <div className={cx(isTablet && 'textCenter')}>
          <img src={caseStudyLogo?.file?.url} alt={caseStudyLogo?.title} />
          <div className={cx('description')}>
            <div
              dangerouslySetInnerHTML={{
                __html: caseStudyExcerpt?.childMarkdownRemark?.html,
              }}
            />
          </div>
        </div>
        <div className={cx('linkWrap')}>
          {caseStudyLink && (
            <div className={cx('link')}>
              <ContentfulLink {...ctaLink}>
                <LinkTextWithChev text={ctaLink.linkText} />
              </ContentfulLink>
            </div>
          )}
        </div>
      </div>
      <div className={cx('imageAndTestimonial')}>
        <div className={cx('wrapTestimonial', !image?.file?.url && 'imageNotSet')}>
          <div className={cx('testimonial')}>
            {image?.file && <div className={cx('imgContainer')}><img src={image?.file?.url} width="100%" alt={image?.title} className={cx('image')} /></div>}
            <DoubleQuotationMarksIcon alt="DoubleQuotationMarks" className={cx('doubleQuotationMarks')} />
            <div className={cx('quote')}>
              <div
                dangerouslySetInnerHTML={{
                  __html: quote?.childMarkdownRemark?.html,
                }}
              />
            </div>
            <div className={cx('author')}>
              <h5 className={cx('name')}>{author}</h5>
              <div className={cx('position')}>{jobDescription}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TestimonialSection = ({
  testimonialLists = []
}) => {
  const [isTablet] = useBreakpoint(styleVars.breakpointTablet, -1)
  useEffect(() => {

  }, [])
  const setHSlide = () =>{
    const active = document.querySelector('[class*=slideTestimonial] .react-multi-carousel-item--active > *')
    if(active){
      const scrollHeight = active.parentElement.offsetHeight
      document.querySelector('[class*=slideTestimonial]').style.height = scrollHeight + 'px'
    }
  }
  return (
    <section className={cx('sectionTestimonials')}>

      {isTablet && (
        <Carousel
          responsive={responsive}
          arrows={false}
          infinite
          customButtonGroup={<CustomButtonGroup total={testimonialLists.length} />}
          containerClass={cx('slideTestimonial')}
          beforeChange={(e, z) => {
            setHSlide()
          }}
          afterChange={(e, z) => {
            setHSlide()
          }}
        >
          {testimonialLists.map((testimonialItem, index) => (
            <TestimonialSlideItem {...testimonialItem} key={`testimonial-slide-${index}`} isTablet={isTablet} />
          ))}
        </Carousel>
      
      )}
      {/* Vì ở mobile không dùng container (padded content) nên phải tách ra dùng 2 ver như này */}
      {!isTablet && (
        <Reveal effect="fadeInUp" duration={1000}>
        <PaddedContent>
          <Carousel
            responsive={responsive}
            arrows={true}
            infinite
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            customButtonGroup={<CustomButtonGroup total={testimonialLists.length} />}
            containerClass={cx('slideTestimonial')}
            beforeChange={(e, z) => {
              setHSlide()
            }}
            afterChange={(e, z) => {
              setHSlide()
            }}
          >
            {testimonialLists.map((testimonialItem, index) => (
              <TestimonialSlideItem {...testimonialItem} key={`testimonial-slide-${index}`} isTablet={isTablet} />
            ))}
          </Carousel>
        </PaddedContent>
        </Reveal>
      )}
    </section>
  )
}

export default TestimonialSection

export const testimonialSection = graphql`
  fragment TestimonialSection on ContentfulTestimonialSection {
    __typename
    id
    testimonialLists {
      author
      quote {
        childMarkdownRemark {
          html
        }
      }
      jobDescription
      caseStudyExcerpt {
        childMarkdownRemark {
          html
        }
      }
      caseStudyLink
      caseStudyLogo {
        file {
          url
        }
        title
      }
      image {
        file {
          url
        }
        title
      }
    }
  }
`
