import React, { useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "components/layout"
import SEO from "components/seo"

import HeroSection from "sections/hero"
import CarouselSection from "sections/carousel"
import GridSection from "sections/grid"
import ImageAndTextSection from "sections/imageAndTextSection"
import ListImageAndTextSection from "sections/listImageAndTextSection"
import ChecklistSection from "sections/checklistSection"
import TextAndImageAboveText from "sections/textAndImageAboveText"
import CtaSection from "sections/ctaSection"
import ContactFormSection from "sections/contactFormSection"
import LinkListSection from "sections/linkListSection"
import TextSection from "sections/textSection"
import Spacer from "sections/spacer"
import PostsSection from "sections/postsSection"
import TabSection from "sections/tabSection"
import TestimonialSection from "sections/testimonialSection"
import ThreeTierDataSection from "sections/threeTierDataSection"
import NewTextSection from "sections/newTextSection"
import TextHeroSection from "sections/textHeroSection"
import IndustriesSection from "sections/industriesSection"
import HomeHeroBannerSection from "sections/homeHeroBannerSection"
// import ErpSolutionsSection from "sections/erpSolutionsSection"
import VideoSection from "sections/videoSection"
import FeaturedArticlesSection from "sections/featuredArticlesSection"
import ImageBlock from "sections/imageBlock"
import FormWidthImageAndTextSection from "sections/formWithImageAndTextSection"
const Page = (props) => {
  const [isHome, setIsHome] = useState(false)
  const [changedPage, setChangedPage] = useState(0)
  // const [heightAlert, setHeightAlert] = useState(0)

  const page = props.data.contentfulPage
  const site = props.data.contentfulSite
  const sections = page?.sections || []
  // const includesHero = sections.some(
  //   (section) => section.__typename === "ContentfulHeroSection"
  // )
  // const lastSection = sections[sections.length - 1]
  // const removeWavyBackground =
  //   (lastSection?.__typename === "ContentfulCtaSection" && lastSection?.backgroundType !== 'white') ||
  //   (lastSection?.__typename === "ContentfulGridSection" &&
  //     lastSection?.type === GRID_TYPES.simpleCard)
  let previousBackgroundType = null
  let currentBackgroundType = null
  // let lastSectionBg = lastSection?.backgroundType || 'white'
  // if (lastSection?.__typename === 'ContentfulCtaSection' && lastSectionBg === 'white') {
  //   lastSectionBg = 'mint'
  // }

  const detectHome = () => {
    setChangedPage(changedPage + 1)
    const pathname = window.location.pathname
    if (['', '/', '/home/', '/devHomePage/'].includes(pathname)) {
      setIsHome(true)
    }
  }

  useLayoutEffect(() => {
    let timeout
    const scrollPage = (event) => {
      if (timeout) {
        clearTimeout(timeout)
      }
      // window.scrollTo(0, 0)
      document.body.style.opacity = 0
      timeout = setTimeout(() => {
        window.scrollTo({
          top: event.state?event.state.scrollPosition:0,
          left: 0,
          behavior: 'smooth'
        });
        document.body.style.opacity = 1
      }, 300);

    }
    //when a user clicks a link to a new page, if  they hit the gack arrow to return the previous page, are we able to return them to the previous scroll location on that page
    window.addEventListener('beforeunload',()=>{
      var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
      window.history.pushState({scrollPosition: scrollPosition}, ''); // 添加历史记录
    })
    window.addEventListener('popstate', scrollPage)
    detectHome()
    return () => {
      window.removeEventListener('popstate', scrollPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [])


  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    if (window.location.hash) {
      // Anchor link Section Id conventions in Contentul
      const scrollToAnchor = (anchorId) => {
        if (anchorId) {
          let anchorElement = document.getElementById(anchorId);
          if (anchorElement) {
            anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
          }
        }
      }

      window.onload = function () {
        scrollToAnchor(window.location.hash.replace("#", ""));
      }
    }
  }

  const waveTypes = ['wavy', 'wavy mint top']
  for (const sIndex in sections) {
    const sectionObj = sections[sIndex]
    if (sectionObj?.__typename === 'ContentfulCtaSection') {
      const nextSectionObj = sections[Number(sIndex) + 1]
      if (nextSectionObj?.__typename === 'ContentfulCtaSection') {
        if (waveTypes.includes(sectionObj?.backgroundType)) {
          nextSectionObj.isHideWave = true
        } else if (waveTypes.includes(nextSectionObj?.backgroundType)) {
          nextSectionObj.bgType = sectionObj.backgroundType || 'mint'
        }
      }
    }
  }

  const ldJson = ([page.schema].flat() || []).reduce((resultArr, sObj) => {
    if (sObj?.internal?.content) {
      resultArr.push(JSON.parse(sObj.internal.content))
    }
    return resultArr
  }, [])

  return (
    <Layout
      // color={page.headerColor}
      page={page}
      changedPage={changedPage}
      includeSecondaryNav={page.includeStickySecondaryNavigation}
      site={site}
    >
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        keywords={page.metaKeywords}
        robot={page.metaRobots}
        slug={page.slug}
        lang={page.node_locale}
      />
      <Helmet>
        <link rel="stylesheet" type="text/css" href="/style/print.css" media="print" />
        {ldJson.length && (
          <script type="application/ld+json">
            {JSON.stringify(ldJson)}
          </script>
        )}
      </Helmet>
      {sections &&
        sections.map((section, i) => {
          const sectionKey = `section-${i}-${section.id}`
          previousBackgroundType = currentBackgroundType
          currentBackgroundType = section.backgroundType
          switch (section.__typename) {
            case "ContentfulImageBlock":
              return <ImageBlock {...section} key={sectionKey} />
            case "ContentfulHeroSection":
              return <HeroSection {...section} key={sectionKey} />
            case "ContentfulCarouselSection":
              return <CarouselSection {...section} key={sectionKey} />
            case "ContentfulGridSection":
              return <GridSection {...section} key={sectionKey} />
            case "ContentfulTextSection":
              return <TextSection {...section} key={sectionKey} />
            case "ContentfulSpacer":
              return <Spacer {...section} key={sectionKey} />
            case "ContentfulPostsSection":
              return <PostsSection {...section} key={sectionKey} />
            case "ContentfulTabSection":
              return <TabSection {...section} key={sectionKey} />
            case "ContentfulTestimonialSection":
              return <TestimonialSection {...section} key={sectionKey} />
            case "ContentfulThreeTierData":
              return <ThreeTierDataSection {...section} key={sectionKey} />
            case "ContentfulNewTextSection":
              return <NewTextSection {...section} key={sectionKey} />
            case "ContentfulTextHeroSection":
              return <TextHeroSection {...section} key={sectionKey} />
            case "ContentfulIndustriesSection":
              return <IndustriesSection {...section} key={sectionKey} />
            case "ContentfulHomeHeroBanner":
              return <HomeHeroBannerSection {...section} key={sectionKey} />
            // case "ContentfulErpSolutionsSection":
            //   return <ErpSolutionsSection {...section} key={sectionKey} />
            case "ContentfulVideo":
              return <VideoSection {...section} key={sectionKey} />
            case "ContentfulFeaturedArticlesSection":
              return <FeaturedArticlesSection {...section} key={sectionKey} />
            case "ContentfulLinkListSection":
              return (
                <LinkListSection
                  {...section}
                  key={sectionKey}
                  spacing={i === 1 ? "small" : null}
                />
              )
            case "ContentfulForm":
              return <ContactFormSection {...section} key={sectionKey} />
            case "ContentfulImageAndTextSection":
              return <ImageAndTextSection {...section} key={sectionKey} />
            case "ContentfulListImageAndTextSection":
              return <ListImageAndTextSection {...section} key={sectionKey} />
            case "ContentfulChecklistSection":
              return <ChecklistSection {...section} key={sectionKey} />
            case "ContentfulTextAndImageAboveText":
              return <TextAndImageAboveText {...section} key={sectionKey} />
            case "ContentfulCtaSection":
              return (
                <CtaSection
                  {...section}
                  previousBackgroundType={previousBackgroundType}
                  key={sectionKey}
                />
              )
            case "ContentfulFormWithImageAndTextSection":
              return (
                <FormWidthImageAndTextSection
                  {...section} key={sectionKey}
                />
              )
            default:
              return null
          }
        })}
      {/* {(!removeWavyBackground) && (
        <WavyBackground
          lightMintBackground={currentBackgroundType === "light mint"}
          bgType={(lastSectionBg || '').toLowerCase()}
        />
      )} */}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageBySlug($slug: String!, $locale: String="en-US") {
    contentfulPage(slug: { eq: $slug }, node_locale: {eq: $locale}) {
      ...Page
    }
    contentfulSite(entryTitle: { eq: "Site" }, node_locale: {eq: $locale}) {
      headerLinks {
        ... on ContentfulMenuItem {
          id
          title
          internalLink
          externalLink
        }
        links {
          id
          linkText
          internalLink
          externalLink
          #icon
          openInNewTab
        }
      }
      footerLinks {
        id
        linkText
        internalLink
        externalLink
        sublinks {
          id
          linkText
          internalLink
          externalLink
        }
      }
      getADemoLinks {
        id
        internalLink
        linkText
      }
      footerText {
        footerText
      }
      footerContent {
        footerContent
      }
      footerLink {
        externalLink
        linkText
      }
    }
  }
`
export const pageFragment = graphql`
  fragment Page on ContentfulPage {
    title
    slug
    node_locale
    metaTitle
    metaDescription
    metaKeywords
    metaRobots
    headerColor
    includeStickySecondaryNavigation
    loginLink
    #schema {
    #  internal {
    #    content
    #  }
    #}
    sections {
      ... on Node {
        ... on ContentfulHeroSection {
          ...HeroSection
        }
        ... on ContentfulImageBlock {
          ...ImageBlock
        }
        ... on ContentfulCarouselSection {
          ...CarouselSection
        }
        ... on ContentfulGridSection {
          ...GridSection
        }
        ... on ContentfulForm {
          ...Form
        }
        ... on ContentfulTextSection {
          ...TextSection
        }
        ... on ContentfulSpacer {
          ...Spacer
        }
        ... on ContentfulCtaSection {
          ...CtaSection
        }
        ... on ContentfulImageAndTextSection {
          ...ImageAndTextSection
        }
        ... on ContentfulListImageAndTextSection {
          ...ListImageAndTextSection
        }
        ... on ContentfulPostsSection {
          ...PostsSection
        }
        ... on ContentfulChecklistSection {
          ...ChecklistSection
        }
        ... on ContentfulTextAndImageAboveText {
          ...TextAndImageAboveText
        }
        ... on ContentfulNewTextSection {
         ...NewTextSection
        }
        ... on ContentfulTextHeroSection {
          ...TextHeroSection
        }
        ... on ContentfulLinkListSection {
          ...LinkListSection
        }
        ... on ContentfulHomeHeroBanner {
          ...HomeHeroBanner
        }
        #... on ContentfulErpSolutionsSection {
        #  ...ErpSolutionsSection
        #}
        ... on ContentfulVideo {
          ...VideoSection
        }
        ... on ContentfulFeaturedArticlesSection {
          ...FeaturedArticlesSection
        }
        ... on ContentfulTabSection {
          ...TabSection
        }
        ... on ContentfulThreeTierData {
          ...ThreeTierData
        }
        ... on ContentfulTestimonialSection {
          ...TestimonialSection
        }
        ... on ContentfulIndustriesSection {
          ...IndustriesSection
        }
        ... on ContentfulFormWithImageAndTextSection {
          ...FormWidthImageAndTextSection
        }
      }
    }
  }
`