import React from 'react'
import PaddedContent from 'components/paddedContent'
// import Reveal from 'react-reveal/Reveal'
import { graphql } from 'gatsby'

import styles from "styles/sections/new-text-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const NewTextSection = ({ name, childContentfulNewTextSectionMarkdownTextNode, backgroundType, buttonText, buttonLink, openInNewTab }) => {
  return (
    <section className={cx('wrapper', {
      navy: backgroundType === "Navy",
      teal: backgroundType === "Teal",
      mint: backgroundType === "Mint",
    })}>
      <PaddedContent>
        {/* <Reveal effect="fadeInUp"> */}
          <>
            {name && <div className={cx('title')}>{name}</div>}
            <div
              dangerouslySetInnerHTML={{
                __html: childContentfulNewTextSectionMarkdownTextNode?.childMarkdownRemark?.html,
              }}
              className={cx('content')}
            />
            {buttonLink && buttonText && (
              <a href={buttonLink} className={styles.cta} target={openInNewTab ? '_blank' : '_self'} rel="norefferer">
                {buttonText}
              </a>
            )}
          </>
        {/* </Reveal> */}
      </PaddedContent>
    </section>
  )
}

export default NewTextSection

export const newTextSection = graphql`
  fragment NewTextSection on ContentfulNewTextSection {
    name
    backgroundType
    childContentfulNewTextSectionMarkdownTextNode {
      childMarkdownRemark {
        html
      }
    }
    buttonText
    buttonLink
    openInNewTab
  }
`
