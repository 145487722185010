import React from "react"
import Reveal from "react-reveal/Reveal"
import { graphql } from 'gatsby'
import "styles/animations.scss"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import TwoColumnList from "components/twoColumnList"
import Video from "components/video"
import ContentfulLink from "components/contentfulLink"
import HubspotForm from "./hubspotForm"

import styles from "styles/sections/image-and-text-section.module.scss"
import classNames from "classnames/bind"
import { BACKGROUND_TYPE } from "constants.js"
const cx = classNames.bind(styles)
const IMAGE_POSITION = {
  'left': 'fadeInLeft',
  'right': 'fadeInRight',
  'top': 'fadeInUp',
  'bottom': 'fadeInDown'
}
const Text_POSITION = {
  'left': 'fadeInRight',
  'right': 'fadeInLeft',
  'top': 'fadeInDown',
  'bottom': 'fadeInUp'
}
const ImageAndTextSection = ({
  sectionId,
  sectionHeading,
  label,
  title,
  description,
  image,
  checklist,
  video,
  imagePosition,
  link,
  backgroundType,
  popupForm
}) => {
  // imagePosition: true => image in right, false => image in left
  const textEffect = Text_POSITION[imagePosition]//imagePosition ? 'fadeInLeft' : 'fadeInRight'
  const imageEffect = IMAGE_POSITION[imagePosition]//imagePosition ? 'fadeInRight' : 'fadeInLeft'
  const showContent = label || title || description || image || video || checklist
  return (
    <section
      id={sectionId}
      className={cx("container", BACKGROUND_TYPE[backgroundType])}
    >
      <PaddedContent>
        {sectionHeading && (
          <SectionHeader
            centered={true}
            {...sectionHeading}
            containerClass={cx('header')}
            maxWidthTitle="815px"
          />
        )}
        {showContent && <div
          className={cx("flex", {
            right: imagePosition === "right" && (image || video),
            top: imagePosition === "top" && (image || video),
            bottom: imagePosition === "bottom" && (image || video),
            hasSectionHeading: sectionHeading,
          })}
        >
          {(image || video) && (
            <Reveal effect={textEffect} duration={700}>
              <div className={styles.imageWrapper}>
                {image?.file?.url && <img src={image.file.url} alt={image.title} />}
                {video && (
                  <Video className={`${styles.video} no-alt`} {...video} />
                )}
              </div>
            </Reveal>
          )}
          <Reveal effect={imageEffect} duration={700}>
            <div className={cx('text', !(image || video) && 'withoutImage')}>
              {(label || title || description) && (
                <div>
                  {label && <div className={styles.label}>{label}</div>}
                  {title && <h2 className={cx("title", "h3")}>{title}</h2>}
                  {description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description.childMarkdownRemark.html,
                      }}
                      className={styles.description}
                    />
                  )}
                </div>
              )}
              {checklist && (
                <div className={styles.listWrapper}>
                  <TwoColumnList red oneColumn list={checklist} />
                </div>
              )}
              {!popupForm && link && (
                <ContentfulLink {...link} className={cx(backgroundType === "Teal" ? styles.ctaTeal : styles.cta)}>
              {link.linkText}
            </ContentfulLink>
              )}
            {popupForm && link && link.linkText && <HubspotForm {...popupForm} text={link.linkText} customerStyle={styles.cta} />}
        </div>
          </Reveal>
    </div>}
      </PaddedContent >
    </section >
  )
}

export default ImageAndTextSection

export const imageAndTextSection = graphql`
  fragment ImageAndTextSection on ContentfulImageAndTextSection {
    __typename
    id
    sectionId
    sectionHeading {
      ...SectionHeading
    }
    label
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    link {
      ...Link
    }
    image {
      file {
        url
      }
      title
    }
    video {
      ...Video
    }
    checklist {
      ... on ContentfulText {
        ...Text
      }
      ... on ContentfulLink {
        ...Link
      }
    }
    imagePosition
    backgroundType
    popupForm {
      id
      formId
      formTarget
      portalId
      subtext {
        subtext
      }
      title
    }
  }
`
