import React from "react"
import { graphql } from 'gatsby'
import Reveal from 'react-reveal/Reveal';
import PaddedContent from "components/paddedContent"
import ContentfulLink from "components/contentfulLink"
import classNames from "classnames/bind"
import styles from "styles/sections/text-section.module.scss"
import { BACKGROUND_TYPE } from "constants.js"
const cx = classNames.bind(styles)

const TextSection = ({title, markdown, textAlignment, backgroundType, link }) => {
  return (
    <section className={cx(BACKGROUND_TYPE[backgroundType])}>
      <PaddedContent>
        <div className={cx(styles.container, {
              left: textAlignment === 'Left',
              right: textAlignment === 'Right'
            })}>
          {markdown && (
            <div
              className={cx(textAlignment === "Center" ? styles.center : "", styles.text)}
              dangerouslySetInnerHTML={{
                __html: markdown.childMarkdownRemark.html,
              }}
            />
          )}
          {link && <Reveal effect="fadeInUp" duration={1000}>
            <ContentfulLink {...link} className={styles.cta}>
              {link?.linkText}
            </ContentfulLink>
          </Reveal>}
        </div>
      </PaddedContent>
    </section>
  )
}

export default TextSection

export const textSection = graphql`
  fragment TextSection on ContentfulTextSection {
    id
    markdown {
      childMarkdownRemark {
        html
      }
    }
    textAlignment
    backgroundType
    link {
      linkText
      internalLink
    }
  }
`
