import React from "react"
import Img from "gatsby-image"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"
import { graphql } from 'gatsby'

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import Video from "components/video"
import ContentfulLink from "components/contentfulLink"
import RightCarrot from "images/icons/right-carrot.svg"

import styles from "styles/sections/text-and-image-above-text.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const ListItem = (props) => {
  if (props.__typename === "ContentfulLink") {
    return (
      <li style={{ marginBottom: props.verticalSpacing }}>
        <ContentfulLink {...props}>
          <span>
            {props.linkText}
            <RightCarrot />
          </span>
        </ContentfulLink>
      </li>
    )
  } else if (props.__typename === "ContentfulText") {
    return (
      <li style={{ marginBottom: props.verticalSpacing }}>
        <span>{props.text}</span>
      </li>
    )
  }
  return null
}

const TextAndImageAboveText = ({
  sectionHeading,
  label,
  title,
  description,
  image,
  checklist,
  video,
  imagePosition,
  link,
  backgroundType,
  textBelowImage
}) => {
  // imagePosition: true => image in right, false => image in left
  const textEffect = imagePosition === "Right" ? 'fadeInLeft' : 'fadeInRight'
  const imageEffect = imagePosition === "Right" ? 'fadeInRight' : 'fadeInLeft'
  return (
    <section
      className={cx("container", {
        white: backgroundType === "white",
        lightMint: backgroundType === "light mint",
        navy: backgroundType === "navy",
      })}
    >
      <PaddedContent>
        {sectionHeading && (
          <SectionHeader
            centered={true}
            {...sectionHeading}
            maxWidthTitle="815px"
          />
        )}
        <div
          className={cx("flex", {
            right: imagePosition==="Right",
            hasSectionHeading: sectionHeading,
          })}
        >
          <Reveal effect={imageEffect} duration={700}>
            <div className={styles.imageWrapper}>
              <div>
                {image && <Img fluid={image.fluid} alt={image.title} />}
                {video && (
                  <Video className={styles.video} {...video} />
                )}
              </div>

              {textBelowImage && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: textBelowImage.childMarkdownRemark.html,
                  }}
                  className={styles.boxTextBelowImage}
                />
              )}
            </div>
          </Reveal>
          <Reveal effect={textEffect} duration={700}>
            <div className={styles.text}>
              {(label || title || description) && (
                <div>
                  {label && <div className={styles.label}>{label}</div>}
                  {title && <h2 className={styles.title}>{title}</h2>}
                  {description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description.childMarkdownRemark.html,
                      }}
                      className={styles.description}
                    />
                  )}
                </div>
              )}
              {checklist && (
                <ul className={styles.checklist}>
                  {checklist.map((item, i) => {
                    return (
                      <ListItem {...item} key={i} />
                    )
                  })}
                </ul>
              )}
              {link && (
                <ContentfulLink {...link} className={styles.cta}>
                  {link.linkText}
                </ContentfulLink>
              )}
            </div>
          </Reveal>
        </div>
      </PaddedContent>
    </section>
  )
}

export default TextAndImageAboveText

export const textAndImageAboveText = graphql`
  fragment TextAndImageAboveText on ContentfulTextAndImageAboveText {
    __typename
    id
    sectionHeading {
      ...SectionHeading
    }
    label
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    link {
      ...Link
    }
    image {
      title
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    video {
      ...Video
    }
    textBelowImage {
      childMarkdownRemark {
        html
      }
    }
    checklist {
      ... on ContentfulText {
        ...Text
      }
    }
    imagePosition
    backgroundType
  }
`
