import React from "react"
import { graphql } from 'gatsby'
import PaddedContent from "components/paddedContent"
import TextAniHero from "components/textAniHero"
import styles from "styles/sections/home-hero-banner-section.module.scss"
import { Helmet } from "react-helmet"
import Reveal from 'react-reveal/Reveal';
import IconNounArrow from "images/icons/arrow_down.svg"
const HomeHeroBannerSection = ({
  header,
  subheader,
  backgroundImageDesktop,
  backgroundImageModile,
  bannerImage
}) => {
  const headerSplitted = (header || '').split('[')
  const headerText = headerSplitted[0]
  let animationText
  if (headerSplitted[1]) {
    animationText = headerSplitted[1].replace(']', '')
  }
  const isWhite = !backgroundImageDesktop && !backgroundImageDesktop
  return (
    <section className={styles.bannerHero + ' ' + `${isWhite ? styles.whiteBg : ""}`}>
      <Helmet>
        {backgroundImageDesktop?.file?.url ? <link rel="preload" as="image" href={backgroundImageDesktop?.file?.url} media="(min-width: 768px)" /> : ''}
        {backgroundImageDesktop?.file?.url ? <link rel="preload" as="image" href={backgroundImageModile?.file?.url} media="(max-width: 768px)" /> : ''}
      </Helmet>
      <div className={styles.wrapper}>
        <div
          className={styles.backgroundDT}
          style={{ backgroundImage: `url(${backgroundImageDesktop?.file?.url})` }}
        ></div>
        <div
          className={styles.backgroundMB}
          style={{ backgroundImage: `url(${backgroundImageModile?.file?.url})` }}
        ></div>
        <PaddedContent>
          <Reveal effect="fadeInUp" duration={1000}>
            <div className={styles.mainContent}>
              <div className={styles.title}>
                <h1>{headerText}
                  {animationText && (<>&nbsp;
                    <TextAniHero text={animationText} />
                  </>
                  )}
                </h1>
                <div className={styles.description}>
                  <h5>
                    {subheader}
                  </h5>
                </div>
                <IconNounArrow
                  className={styles.iconNounArrow}
                ></IconNounArrow>
              </div>
            </div>
          </Reveal>
          <div className={styles.imageWrapper}>
            <div className={styles.mainImage + ' ' + `${headerText ? "" : styles.small}`}>
              <img className={styles.EncompassDevicesImg} src={bannerImage?.file?.url} alt={bannerImage?.title}></img>
            </div>
          </div>
        </PaddedContent>
      </div>
    </section>
  )
}

export default HomeHeroBannerSection

export const homeHeroBanner = graphql`
  fragment HomeHeroBanner on ContentfulHomeHeroBanner {
    __typename
    header
    subheader
    backgroundImageDesktop {
      file {
        url
      }
    }
    backgroundImageModile {
      file {
        url
      }
    }
    bannerImage {
      file {
        url
      }
      title
    }
    buttonText
    buttonLink
    openInNewTab
  }
`
