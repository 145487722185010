import React from 'react'
import Reveal from 'react-reveal/Reveal'

import PaddedContent from 'components/paddedContent'
import SectionHeader from "components/sectionHeader"
import styles from "styles/sections/grid/image-grid-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const imageGridSection = ({ sectionHeading, gridItems }) => {
  return (
    <PaddedContent>
      <Reveal effect="fadeInUp">
      <>
      {sectionHeading && <PaddedContent>
          <SectionHeader
            title={sectionHeading.title}
            label={sectionHeading.label}
            subtext={sectionHeading.subtext}
            centered
            maxWidthTitle="unset"
            maxWidthSubtext="888px"
          />
        </PaddedContent>}
        </>
      </Reveal>

      <Reveal effect="fadeInUp">
        <div className={cx('wrapGridItems')}>
          {gridItems && gridItems.map((gridItem, index) => (
            <div>
              { gridItem.image.description&&gridItem.image.description.includes("http")?(
                  <a href={gridItem.image.description}>
                    <img src={gridItem?.image?.file?.url} className={cx('gridItem')} width="100%" key={`image-grid-item-${index}`} alt={gridItem.name} />
                  </a>
                  ) : (
                    <img src={gridItem?.image?.file?.url} className={cx('gridItem')} width="100%" key={`image-grid-item-${index}`} alt={gridItem.name} />
                  )
                }
            </div>
          ))}
        </div>
      </Reveal>
    </PaddedContent>
  )
}

export default imageGridSection