import React from "react"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"
import ContentfulLink from "components/contentfulLink"
import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"
import Card from "components/card"
import styles from "styles/sections/grid/card-section.module.scss"
import classNames from "classnames/bind"
import { BACKGROUND_TYPE } from "constants.js"
const cx = classNames.bind(styles)

const CardSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
  link
}) => {
  return (
    <section
      className={cx("container", BACKGROUND_TYPE[backgroundType])}
      id={sectionId}
    >
      <PaddedContent>
        <Reveal effect="fadeUp" duration={700}>
          <>
            {sectionHeading && (
              <SectionHeader centered={true} {...sectionHeading} />
            )}
            {gridItems && (
              <div className={styles.cardContainer}>
                {gridItems.map((card, i) => (
                  <Card horizontal {...card} withoutAnimation={true} key={i} />
                ))}
              </div>
            )}
            <ContentfulLink {...link} className={styles.cta}>
              {link?.linkText}
            </ContentfulLink>
          </>
        </Reveal>
      </PaddedContent>
    </section>
  )
}

export default CardSection
