import React from "react"
import PropTypes from "prop-types"
import ContentfulLink from "components/contentfulLink"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"
import { useBreakpoint } from "hooks/use-breakpoint"

import RightCarrot from "images/icons/right-carrot.svg"

import styles from "styles/components/two-column-list.module.scss"
import styleVars from "styles/variables.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const ListItem = ({isTablet, ...props}) => {
  const numbVerticalSpacing = parseFloat(String(props.verticalSpacing).replace('px', ''))
  const styleObj = numbVerticalSpacing > 20 && isTablet ? {}: { marginBottom: props.verticalSpacing }
  if (props.__typename === "ContentfulLink") {
    return (
      <Reveal effect="fadeUp" duration={200}>
        <li style={styleObj}>
          <ContentfulLink {...props}>
            <span>
              {props.linkText}
              <RightCarrot />
            </span>
          </ContentfulLink>
        </li>
      </Reveal>
    )
  } else if (props.__typename === "ContentfulText") {
    return (
      <Reveal effect="fadeUp" duration={200}>
        <li style={styleObj}>
          <span>{props.text}</span>
        </li>
      </Reveal>
    )
  }
  return null
}

const TwoColumnList = (props) => {
  let {
    list,
    demibold,
    red,
    verticalSpacing,
    oneColumn,
    hideCheckmarks,
  } = props

  const [isTablet] = useBreakpoint(styleVars.breakpointTablet, -1)
  if (!list) return null
  const isShowOneColumn = oneColumn ? oneColumn: list.length <= 4
  const isShowThreeColumn = list.length >10
  let halfway = 0
  if(isShowThreeColumn){
    halfway = Math.ceil(list.length / 3)
  } else {
    halfway = Math.ceil(list.length / 2)
  }
  let column1 = isShowOneColumn ? list : list.slice(0, halfway)
  let column3 = isShowThreeColumn? list.slice(halfway, 2*halfway):[]
  let column2 = isShowThreeColumn? list.slice(2*halfway):list.slice(halfway)
  const numbVerticalSpacing = parseFloat(String(verticalSpacing).replace('px', ''))
  const styleObj = numbVerticalSpacing > 20 && isTablet ? {}: { marginBottom: verticalSpacing }

  return (
    <div className={cx("row", isShowOneColumn && 'justifyLeft', { red, demibold, oneColumn, hideCheckmarks },isShowThreeColumn && 'threeColumn')}>
      <ul className={styles.column + ' ' + (props.listStyle ? styles[props.listStyle] : '')} style={styleObj}>
        {column1.map((item, i) => {
          return (
            <ListItem {...item} key={i} verticalSpacing={i === column1.length - 1 ? 0 : verticalSpacing} isTablet={isTablet} />
          )
        })}
      </ul>
      {!isShowOneColumn && isShowThreeColumn && (
      <ul className={styles.column + ' ' + (props.listStyle ? styles[props.listStyle] : '')}>
        {column3.map((item, i) => {
          return (
            <ListItem {...item} key={i} verticalSpacing={i === column3.length - 1 ? 0 : verticalSpacing} isTablet={isTablet} />
          )
        })}
      </ul>
      )}
      {!isShowOneColumn && (
      <ul className={styles.column + ' ' + (props.listStyle ? styles[props.listStyle] : '')}>
        {column2.map((item, i) => {
          return (
            <ListItem {...item} key={i} verticalSpacing={i === column2.length - 1 ? 0 : verticalSpacing} isTablet={isTablet} />
          )
        })}
      </ul>
      )}
    </div>
  )
}

TwoColumnList.propTypes = {
  list: PropTypes.array.isRequired,
  bold: PropTypes.bool,
  red: PropTypes.bool,
}

export default TwoColumnList
