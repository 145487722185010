import React from "react"
import { Link } from "gatsby"

import PaddedContent from "components/paddedContent"
import PageHeader from "components/pageHeader"

import styles from "styles/sections/hero/image-hero.module.scss"
import styleVars from "styles/variables.scss"
import { useBreakpoint } from "hooks/use-breakpoint"
import { getNewLink } from "variables.js"
const ImageHero = ({
  label,
  header,
  subheader,
  buttonText,
  buttonLink,
  heroImageMobile,
  heroImageDesktop,
  backgroundType,
  heightDesktop,
  heightMobile
}) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet)

  return (
    <section className={styles.wrapper} style={{
      height: `${isTablet ? heightMobile + "px" : heightDesktop + "px"}`
    }}>
      <div className={styles.background} style={{
        backgroundImage: loaded && `url(${isTablet ? (heroImageMobile?.file?.url || heroImageDesktop?.file?.url) : heroImageDesktop?.file?.url})`
      }}>
        <div className={backgroundType !== "none" ? styles.overlay : ""} />
      </div>
      <PaddedContent>
        <div className={styles.content}>
          {header && (<PageHeader
            white
            centered
            maxWidthTitle="800px"
            maxWidthSubtext="700px"
            label={label}
            title={header}
            subtext={subheader}
          />)}
          {buttonLink && buttonText && (
            <Link to={getNewLink(buttonLink)} className={styles.cta}>
              {buttonText}
            </Link>
          )}
        </div>
      </PaddedContent>
    </section>
  )
}

export default ImageHero
