import React from "react"

import SectionHeader from "components/sectionHeader"
import ArticlesGrid from "components/articlesGrid"

import styles from "styles/sections/grid/article-grid-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const ArticleGridSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
  loadMoreButton
}) => {
  return (
    <section
      className={cx("container", {
        white: backgroundType === "White",
        lightMint: backgroundType === "Light Gray",
        mint: backgroundType === "Dark Gray",
      })}
      id={sectionId}
    >
      <div className={styles.headerContainer}>
        <SectionHeader {...sectionHeading} centered maxWidthTitle="600px" />
      </div>
      <ArticlesGrid articles={gridItems} buttonText={loadMoreButton}/>
    </section>
  )
}

export default ArticleGridSection
