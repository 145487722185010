import React from "react"
import PaddedContent from "components/paddedContent"
import CtaSection from "sections/ctaSection"

import styles from "styles/sections/hero/mix-hero.module.scss"
import styleVars from "styles/variables.scss"
import { useBreakpoint } from "hooks/use-breakpoint"
import classNames from "classnames/bind"
import { Link } from "gatsby"
import { getNewLink } from "variables.js"
const cx = classNames.bind(styles)
const MixHero = ({
  header,
  subheader,
  buttonLink,
  buttonText,
  link,
  heroImageMobile,
  heroImageDesktop,
  heightDesktop,
  imagePosition,
  contentImage,
  mapText,
  backgroundType
}) => {
  const [isTablet, loaded] = useBreakpoint(styleVars.breakpointTablet)

  return (
    <section className={styles.wrapper} style={{
      height: `${isTablet ? "100%" : heightDesktop + "px"}`
    }}>
      <div className={styles.background} style={{
        backgroundImage: loaded && `url(${isTablet ? (heroImageMobile?.file?.url || heroImageDesktop?.file?.url) : heroImageDesktop?.file?.url})`
      }}>
        <div className={backgroundType !== "none" ? styles.overlay : ""} />
      </div>
      <PaddedContent>
        <div className={cx(styles.contanier, imagePosition)}>
          <div className={styles.title}>
            {subheader ? (
              <div className={styles.subtext}>
                <h2>{header}</h2>
                <p>{subheader}</p>
              </div>
            ) : <h1>{header}</h1>}

            {mapText && (
              <div
                dangerouslySetInnerHTML={{
                  __html: mapText?.childMarkdownRemark?.html,
                }}
              />
            )}
          </div>
          <div className={styles.line}>
            <img src={contentImage?.file?.url}></img>
          </div>
          <div className={styles.content}>
            {link && (
              <CtaSection {...link} />
            )}
            {buttonLink && buttonText && (
              <Link to={getNewLink(buttonLink)} className={styles.cta}>
                {buttonText}
              </Link>
            )}
          </div>
        </div>
      </PaddedContent>
    </section>
  )
}

export default MixHero
