import React from "react"
import Reveal from "react-reveal/Reveal"
import "styles/animations.scss"

import PaddedContent from "components/paddedContent"
import SectionHeader from "components/sectionHeader"

import styles from "styles/sections/grid/text-grid-section.module.scss"
import classNames from "classnames/bind"
import {BACKGROUND_TYPE} from "constants.js"
const cx = classNames.bind(styles)

const TextBlock = ({ title, text, longText, icon }) => {
  return (
    <div className={styles.textBlock}>
      {icon?.file?.url && (
        <Reveal effect="fadeIn">
          <div className={styles.icon}>
            <img src={icon.file.url} alt={title} />
          </div>
        </Reveal>
      )}
      <div className={styles.textGroup}>
        <Reveal effect="fadeIn">
          <h2 className={cx("title", "h5")}>{title}</h2>
        </Reveal>
        <Reveal effect="fadeUp" duration={200}>
          <>
            {longText && (
              <div
                dangerouslySetInnerHTML={{
                  __html: longText?.childMarkdownRemark?.html,
                }}
                className={styles.text}
              />
            )}
            {text && (
              <p className={styles.text}>{text}</p>
            )}
          </>
        </Reveal>
      </div>
    </div>
  )
}

const TextGridSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
}) => {
  return (
    <section
      className={cx("container", BACKGROUND_TYPE[backgroundType])}
      id={sectionId}
    >
      <PaddedContent>
        {sectionHeading && (
          <SectionHeader
            centered={true}
            {...sectionHeading}
            maxWidthTitle="850px"
          />
        )}
        {gridItems && (
          <div className={styles.textBlockGrid}>
            {gridItems.map((textBlock, i) => {
              return <TextBlock {...textBlock} key={i} />
            })}
          </div>
        )}
      </PaddedContent>
    </section>
  )
}

export default TextGridSection
