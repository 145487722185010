import React from "react"
import { useBreakpoint } from "hooks/use-breakpoint"

import PaddedContent from "components/paddedContent"
import PageHeader from "components/pageHeader"

import styles from "styles/sections/hero/text-center-hero.module.scss"
import BackgroundHero from "components/backgroundHero"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const TextCenterHero = ({
  header,
  subheader,
  buttonLink,
  buttonText,
  openInNewTab,
  backgroundType,
  shortBanner
}) => {
  const [isTablet] = useBreakpoint('767px')

  return (
    <section className={cx('wrapper', shortBanner ? 'shortBanner' : '')}>
      <div className={styles.background}>
        <BackgroundHero backgroundType={backgroundType} isTablet={isTablet} />
      </div>
      <PaddedContent>
        <div className={cx(styles.content, backgroundType)}>
          <PageHeader
            white
            centered
            maxWidthTitle="950px"
            maxWidthSubtext="950px"
            title={header}
            subtext={subheader}
          />
          {buttonLink && buttonText && (
            <a href={buttonLink} className={styles.cta} target={openInNewTab ? '_blank' : '_self'} rel="norefferer">
              {buttonText}
            </a>
          )}
        </div>
      </PaddedContent>
    </section>
  )
}

export default TextCenterHero
