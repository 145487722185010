import React from "react"

import SectionHeader from "components/sectionHeader"
import ArticlesGrid from "components/articlesGrid"

import styles from "styles/sections/grid/external-article-section.module.scss"
import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const ExternalArticleSection = ({
  sectionHeading,
  gridItems,
  sectionId,
  backgroundType,
}) => {
  return (
    <section
      className={cx("container", {
        white: backgroundType === "White",
        lightMint: backgroundType === "Light Gray",
        mint: backgroundType === "Dark Gray",
      })}
      id={sectionId}
    >
      {sectionHeading && (
        <div className={styles.headerContainer}>
          <SectionHeader
            {...sectionHeading}
            centered
            maxWidthSubtext="690px"
            demibold
          />
        </div>
      )}
      <ArticlesGrid external articles={gridItems} />
    </section>
  )
}

export default ExternalArticleSection
